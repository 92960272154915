var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.products
    ? _c("div", [
        _c("div", [
          _c("h1", { staticStyle: { "line-height": "3rem" } }, [
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      `${
                        _vm.hasFavorites
                          ? "favourites.preferred"
                          : "favourites.bestselling"
                      }`
                    )
                  ) +
                  " "
              )
            ])
          ]),
          _c("div", { staticClass: "text-body-2 default--text" }, [
            _c("p", [
              _vm._v(
                "Sono stati trovati " +
                  _vm._s(_vm.products.length) +
                  " prodotti"
              )
            ])
          ])
        ]),
        _vm.products.length > 0
          ? _c(
              "div",
              { staticStyle: { "max-height": "100%" } },
              [
                _c(
                  "v-row",
                  _vm._l(_vm.products, function(product) {
                    return _c(
                      "v-col",
                      {
                        key: product.productId,
                        attrs: { cols: "12", sm: "3", lg: "3" }
                      },
                      [_c("ProductCard", { attrs: { product: product } })],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }